import Vue from "vue";
import VueMeta from "vue-meta";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import css from "./css/style.scss";
import VueAgile from "vue-agile";
Vue.use(VueAgile);
Vue.use(VueMeta);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  css,
  VueAgile,
  render: (h) => h(App),
}).$mount("#app");
