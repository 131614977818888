import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about/",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/flow/",
    name: "Flow",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Flow.vue"),
  },
  {
    path: "/delivery/",
    name: "Delivery",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Delivery.vue"),
  },
  //シーン
  {
    path: "/scene/exhibition/",
    name: "Exhibition",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/scene/exhibition.vue"),
  },
  {
    path: "/scene/festival/",
    name: "Festival",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/scene/festival.vue"),
  },
  {
    path: "/scene/food/",
    name: "Food Event",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/scene/foodevent.vue"),
  },
  {
    path: "/scene/freemarket/",
    name: "Free Market",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/scene/freemarket.vue"),
  },
  {
    path: "/scene/ceremony/",
    name: "Ceremony",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/scene/ceremony.vue"),
  },
  {
    path: "/scene/presentation/",
    name: "Presentation",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/scene/presentation.vue"),
  },
  {
    path: "/scene/conference/",
    name: "Conference",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/scene/conference.vue"),
  },
  {
    path: "/scene/briefing/",
    name: "Conference",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/scene/briefing.vue"),
  },
  {
    path: "/scene/awards/",
    name: "Awards",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/scene/awards.vue"),
  },
  {
    path: "/scene/funeral/",
    name: "Funeral",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/scene/funeral.vue"),
  },
  {
    path: "/scene/sports/",
    name: "Sports",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/scene/sports.vue"),
  },
  //カテゴリー
  {
    path: "/category/:id",
    name: "Category",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Category.vue"),
  },
  {
    path: "/product/:id",
    name: "Product",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Product.vue"),
  },
  {
    path: "/cart/",
    name: "Cart",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Cart.vue"),
  },
  {
    path: "/search/",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Search.vue"),
  },
  {
    path: "/search/:keyword",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Search.vue"),
  },
  //
  // 管理画面
  //
  {
    path: "/admin/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/Login.vue"),
  },
  {
    path: "/admin/home",
    name: "AdminHome",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/Home.vue"),
  },
  //- 設定
  {
    path: "/admin/settings",
    name: "Settings",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/settings/Index.vue"
      ),
  },
  // カテゴリ
  {
    path: "/admin/category/",
    name: "category",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/category/Index.vue"
      ),
  },
  {
    path: "/admin/category/add/",
    name: "categoryAdd",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/category/Add.vue"),
  },
  {
    path: "/admin/category/edit/:id",
    name: "categoryEdit",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/category/Edit.vue"
      ),
  },
  // 商品
  {
    path: "/admin/item/",
    name: "item",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/item/Index.vue"),
  },
  {
    path: "/admin/item/add/",
    name: "itemAdd",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/item/Add.vue"),
  },
  {
    path: "/admin/item/edit/:id",
    name: "itemEdit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/item/Edit.vue"),
  },
  {
    path: "/admin/item/preview/:id",
    name: "itemPreview",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/item/Preview.vue"),
  },
  {
    path: "/admin/item/preview/:cache/:id",
    name: "itemPreview",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/admin/item/Preview.vue"),
  },
  // 見積り
  {
    path: "/admin/estimate/",
    name: "estimate",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/estimate/Index.vue"
      ),
  },
  {
    path: "/admin/estimate/search/:keyword",
    name: "estimateSearch",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/estimate/Search.vue"
      ),
  },
  {
    path: "/admin/estimate/detail/:id",
    name: "estimateDetail",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/estimate/Detail.vue"
      ),
  },
  {
    path: "/admin/estimate/edit/:id",
    name: "estimateEdit",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/estimate/Edit.vue"
      ),
  },
  {
    path: "/admin/estimate/confirm/:id",
    name: "estimateConfirm",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/admin/estimate/Confirm.vue"
      ),
  },
];

const router = new VueRouter({
  routes,
  //遷移時に上までスクロール、遷移時のアンカーリンクに対応
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: async (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }

    const findEl = async (hash, x) => {
      return (
        document.querySelector(hash) ||
        new Promise((resolve) => {
          if (x > 50) {
            return resolve();
          }
          setTimeout(() => {
            resolve(findEl(hash, ++x || 1));
          }, 100);
        })
      );
    };

    if (to.hash) {
      const el = await findEl(to.hash);

      return window.scrollTo({ top: el.offsetTop, behavior: "smooth" });
    }

    return { x: 0, y: 0 };
  },
});

export default router;
