<template lang="pug">
  header#header
    #searchBox(:class="{ 'active': searchBox }")
      .close(@click="searchBox = !searchBox")
      form(@submit.prevent="item_search")
        input(type="search" placeholder="商品を検索する" @input="change_keyword")
        button(type="submit") 検索
    .inner
      router-link#logo(to="/")
        img(src="@/assets/logo-horizontal-1.svg")
      nav.nav(:class="{isVisible: burgerVisible}")
        .nav__link(@click="searchBox = !searchBox, burgerVisible = !burgerVisible")
          span.ja 商品検索
          span.en Search
        span.nav__separator
        router-link.nav__link(:to="{name: 'Home', hash: '#category'}" @click="burgerVisible = !burgerVisible")
          span.ja カテゴリから探す
          span.en Category
        span.nav__separator
        router-link.nav__link(:to="{name: 'Home', hash: '#scene'}" @click="burgerVisible = !burgerVisible")
          span.ja シーン別に探す
          span.en Scene
      a.tel.m-md(href="tel:06-6534-5963")
        img(src="@/assets/header-tel.svg" alt="06-6534-5963 受付時間: 10:00 〜 18:00（土日祝除く）")
      .btns
        span.separator.m-md
        router-link.btns-item(to="/cart/")
          span.icon
            img(src="@/assets/icon-cart-1.svg")
          span.ja 見積りカート
        span.separator
        .m-sm
          .btns-item(@click="searchBox = !searchBox")
            span.icon
              img(src="@/assets/icon-search.svg")
            span.ja 検索
        span.separator
        a.btns-item.contact(href="#contact" @click="burgerVisible = !burgerVisible")
          span.icon
            img(src="@/assets/icon-mail-1.svg")
          span.ja お問い合わせ
        .btns-item.burger(@click="burgerVisible = !burgerVisible")
          span.burger-line(:class="{open: burgerVisible}")
            span.line
            span.line
            span.line
          span.ja メニュー


</template>

<script>
export default {
  name: "Header",
  props: {
    msg: String,
  },
  data: function () {
    return {
      burgerVisible: false,
      keyword: "",
      searchBox: false,
    };
  },
  methods: {
    change_keyword(e) {
      this.keyword = e.target.value;
    },
    item_search() {
      this.$router.push("/search/" + this.keyword);
    },
  },
  watch: {
    $route() {
      location.reload();
    }
  }
};
</script>
<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
#searchBox {
  position: fixed;
  @include mixin.liquidSize(top, 100, 100);
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 101;
  pointer-events: none;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  backdrop-filter: blur(5px);
  background: rgba(255, 255, 255, 0.7);
  @include media.max-screen(media.$breakpoint-sm) {
    
  }
  @include media.min-screen(media.$breakpoint-md) {
    justify-content: center;
  }
  &.active {
    pointer-events: auto;
    opacity: 1;
  }
  input[type="search"] {
    box-sizing: border-box;
    border: solid 1px #a2a2a2;
    @include mixin.liquidSize(width, 465, 450);
    height: 35px;
    border-radius: 21px;
    padding: 0 25px;
    background: {
      color: #fff;
      image: url(../../../assets/icon-search-1.svg);
      repeat: no-repeat;
      position: top 10px right 10px;
      size: 15px auto;
    }
    @include mixin.liquidSize(margin-left, 0, 40);
  }
  button {
    width: 70px;
    height: 35px;
    margin-left: 10px;
    background: #1890a7;
    color: #fff;
    font-size: 13px;
    transition: all 0.3s ease-in-out;
    border-radius: 16px;
    &:hover {
      background: #12869b;
      color: #fff;
    }
  }
  .close {
    position: absolute;
    top: 50%;
    @include mixin.liquidSize(left, 280, 290);
    margin-left: 50%;
    transform: translate(0, -50%);
    width: 35px;
    height: 35px;
    //background: #888;
    cursor: pointer;
    &:before,
    &:after {
      content: "";
      width: 25px;
      height: 1px;
      background: #707070;
      position: absolute;
      left: 50%;
      top: 50%;
      translate: -50% -50%;
    }
    &:before {
      rotate: 45deg;
    }
    &:after {
      rotate: -45deg;
    }
  }
}
#header {
  width: 100%;
  @include mixin.liquidSize(height, 100, 100);
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-sizing: border-box;
  @include mixin.liquidSize(padding-left, 35, 0);
  @include mixin.liquidSize(padding-right, 35, 0);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}
.inner {
  //@include mixin.liquidSize(max-width, 1320, 750);
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  @include mixin.liquidSize(padding-left, 0, 30);
}
#logo {
  @include mixin.liquidSize(width, 133, 133);
  @include mixin.liquidSize(height, 62, 62);
  img {
    width: 100%;
  }
}
.nav {
  display: flex;
  @include mixin.liquidSize(padding-top, 0, 100);
  @include media.max-screen(media.$breakpoint-sm) {
    flex-direction: column;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transform: scale(0) translate(0, 10%);
    width: 100%;
    height: 100%;
    background: #fff;
    transition: all 0.3s ease-in-out;
    position: fixed;
    left: 0;
    top: calc(100 / 750 * 100vw);
    z-index: 100;
    &.isVisible {
      opacity: 1;
      pointer-events: all;
      transform: scale(1) translate(0, 0%);
    }
  }
  @include media.min-screen(media.$breakpoint-md) {
    margin-left: 80px;
    align-items: center;
  }
}
.nav__link {
  color: #000;
  @include mixin.liquidSize(padding-left, 35, 30);
  @include mixin.liquidSize(padding-right, 35, 30);
  @include mixin.liquidSize(padding-top, 0, 50);
  @include mixin.liquidSize(padding-bottom, 0, 50);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include mixin.liquidSize(height, 100, 100);
  position: relative;
  &:before {
    content: "";
    width: 0;
    height: 2px;
    background: #1890a7;
    position: absolute;
    right: 0%;
    bottom: 0;
    transition: all 0.4s ease-in-out 0.1s;
  }
  &.isSeeing {
    &:before {
      animation: seeing 0.4s ease-in-out 0.1s forwards;
    }
  }
  @include media.min-screen(media.$breakpoint-md) {
    &:hover {
      &:before {
        width: 100%;
        left: 0;
      }
    }
  }
  .ja {
    @include mixin.liquidSize(font-size, 15, 15);
    letter-spacing: -0.005em;
    font-weight: 400; /* regular */
    margin-bottom: 3px;
  }
  .en {
    @include mixin.liquidSize(font-size, 10, 10);
    color: #989898;
    letter-spacing: -0.015em;
    font-weight: 400; /* regular */
  }
}
.nav__separator {
  width: 1px;
  height: 60px;
  @include mixin.liquidSize(width, 1, 500);
  @include mixin.liquidSize(height, 60, 1);
  @include media.max-screen(media.$breakpoint-sm) {
    border-bottom: dashed 1px #cecece;
  }
  @include media.min-screen(media.$breakpoint-md) {
    border-right: dashed 1px #cecece;
  }
}
.tel {
  margin-left: auto;
  @include mixin.liquidSize(width, 200, 0);
}
.btns {
  display: flex;
  align-items: center;
  @include media.max-screen(media.$breakpoint-sm) {
    margin-left: auto;
  }
  @include media.min-screen(media.$breakpoint-md) {
    margin-left: 19px;
  }
  .separator {
    border-right: dashed 1px #cecece;
    @include mixin.liquidSize(height, 70, 70);
  }
}
.btns-item {
  @include mixin.liquidSize(width, 100, 120);
  @include mixin.liquidSize(height, 100, 70);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 65px;
  position: relative;
  &:before {
    content: "";
    width: 0;
    height: 2px;
    background: #1890a7;
    position: absolute;
    right: 0%;
    bottom: 0;
    transition: all 0.4s ease-in-out 0.1s;
  }
  @include media.max-screen(media.$breakpoint-sm) {
    &.contact {
      display: none;
    }
  }
  @include media.min-screen(media.$breakpoint-md) {
    &.burger {
      display: none;
    }
    &:hover {
      &:before {
        width: 100%;
        left: 0;
      }
    }
  }
  .icon,
  .burger-line {
    @include mixin.liquidSize(width, 27, 27);
    @include mixin.liquidSize(height, 22, 22);
  }
  .icon {
    @include mixin.liquidSize(margin-bottom, 8, 0);
  }
  .ja {
    @include mixin.liquidSize(font-size, 12, 20);
    color: #000;
    font-weight: 500; /* medium */
    @include media.max-screen(media.$breakpoint-sm) {
      transform: scale(0.75);
    }
  }
}

.burger-line {
  display: flex;
  flex-direction: column;
  .line {
    background: #707070;
    height: 1px;
    position: relative;
    transition: all 0.5s ease;
    top: 0;
    &:nth-of-type(1),
    &:nth-of-type(2) {
      @include mixin.liquidSize(width, 0, 18);
      margin-bottom: 3px;
    }
    &:nth-of-type(3) {
      @include mixin.liquidSize(width, 0, 14);
    }
  }
  &.open {
    .line {
      &:nth-of-type(1) {
        transition: all 0.5s ease 0.1s;
        rotate: 45deg;
        @include mixin.liquidSize(top, 0, 8);
      }
      &:nth-of-type(2) {
        transition: all 0.3s ease;
        width: 0;
        @include mixin.liquidSize(margin-left, 0, 18);
      }
      &:nth-of-type(3) {
        transition: all 0.5s ease 0.1s;
        rotate: -45deg;
        @include mixin.liquidSize(width, 0, 18);
        @include mixin.liquidSize(top, 0, -7);
      }
    }
  }
}
</style>
