<template lang="pug">
  .m-content
    h3.head
      head1(ja="取引の流れ" en="Flow" desc="見積作成や発注までの流れをご説明いたします。" type="v")
    ul.list
      li.list-item
        .list-img
          img(src="@/assets/img-flow-1.png")
        span.list-head 1. 見積りを作成
        p.list-desc お求めの商品をショッピングカートにお入れ頂き、お見積りを作成します。お見積は、自動で作成されメールでお届け致します。
      li.list-item
        .list-img
          img(src="@/assets/img-flow-2.png")
        span.list-head 2. 仮予約又はお問い合せ
        p.list-desc お見積り作成時に仮予約をして、商品を仮抑えすることができます。こちらの手順を飛ばして、お電話からも仮予約・正式見積りを取る事もできます。
      li.list-item
        .list-img
          img(src="@/assets/img-flow-3.png")
        span.list-head 3. 正式見積り
        p.list-desc お電話またはメールからお問合せ頂いたお客様には弊社スタッフがご連絡して正式なお見積りを作成します。
      li.list-item
        .list-img
          img(src="@/assets/img-flow-4.png")
        span.list-head 4. 正式発注
        p.list-desc 正式見積りの内容をご確認頂いてから正式発注を承ります。
</template>

<script>
import head1 from "@/components/common/head-1.vue";
export default {
  name: "scene",
  components: {
    head1,
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.head {
  @include mixin.liquidSize(margin-bottom, 55, 100);
}

.list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include mixin.liquidSize(padding-bottom, 145, 0);
}

.list-item {
  @include mixin.liquidSize(width, 260, 331);
  @include mixin.liquidSize(margin-left, 25, 0);
  @include mixin.liquidSize(margin-right, 25, 0);
  @include mixin.liquidSize(margin-bottom, 0, 100);
  @include media.max-screen(media.$breakpoint-sm) {
    &:nth-of-type(odd) {
      margin-right: auto;
    }
  }
}

.list-img {
  @include mixin.liquidSize(height, 224, 224);
  position: relative;
  img {
    max-width: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
  }
}

.list-head {
  display: block;
  color: #50aabb;
  font-weight: 700; /* bold */
  @include mixin.liquidSize(font-size, 18, 25);
  text-align: center;
  @include mixin.lhFix(1);
  @include mixin.liquidSize(margin-bottom, 20, 15);
}

.list-desc {
  font-weight: 500; /* medium */
  @include mixin.liquidSize(font-size, 15, 26);
  @include mixin.lhFix(2);
  line-height: 2;
}
</style>
