<template lang="pug">
  .home
    Header
    //- KV
    main.user
      #kv
        agile(ref="main" :options="options1" :speed="500" :as-nav-for="asNavFor1" class="main")
          .slide.slide--main(v-for="(slide, index) in slides" :key="index" :class="`slide--${index}`")
            img.m-sm(:src="slidesSM[index]")
            img.m-md(:src="slide")
        agile.m-md(ref="thumbnails" :options="options2" :speed="500" :as-nav-for="asNavFor2" class="thumbnails")
          .slide.slide--thumbnail(v-for="(slide, index) in slides" :key="index" @click="$refs.thumbnails.goTo(index);" :class="`slide--${index}`")
            img(:src="slide")
      //- バナー
      bnrs

      section#category
        .m-content
          h3.head
            head1(ja="カテゴリから探す" en="Category" type="h")
        .category-blk
          category(:categoryList="categoryList")

      section#scene
        .m-content
          h3.head
            head1(ja="シーン別に探す" en="Scene" type="h")
        scene

      section#flow
        flow

      contact

      Footer
</template>

<script>
// @ is an alias to /src
import Header from "@/components/user/common/header.vue";
import head1 from "@/components/common/head-1.vue";
import category from "@/components/user/home/category.vue";
import scene from "@/components/user/common/scene.vue";
import flow from "@/components/user/common/flow.vue";
import contact from "@/components/user/common/contact.vue";
import Footer from "@/components/user/common/footer.vue";
import bnrs from "@/components/user/common/bnrs.vue";
export default {
  name: "Home",
  metaInfo: {
    title: "大阪でイベント備品のレンタルならカリトク",
    meta: [
      {
        name: "description",
        content:
          "大阪を中心にイベント備品のレンタルならカリトクをご活用ください。大阪、兵庫、京都、奈良、和歌山でイベント備品を貸出し、配送も対応しております。",
      },
      { name: "keywords", content: "" },
    ],
  },
  components: {
    Header,
    Footer,
    head1,
    category,
    scene,
    flow,
    contact,
    bnrs,
  },
  data: function () {
    return {
      asNavFor1: [],
      asNavFor2: [],
      slides: [
        require("@/assets/slide-1__md.jpg"),
        require("@/assets/slide-2__md.jpg"),
        //require("@/assets/slide-3__md.jpg"),
      ],
      slidesSM: [
        require("@/assets/slide-1__sm.jpg"),
        require("@/assets/slide-2__sm.jpg"),
        //require("@/assets/slide-3__sm.jpg"),
      ],
      options1: {
        autoplay: false,
        dots: true,
        fade: false,
        navButtons: false,
        autoplaySpeed: 1000,
        timing: "ease-in-out",
        slidesToShow: 1,
        speed: 100,
        responsive: [
          {
            breakpoint: 750,
            settings: {
              dots: false,
            },
          },
        ],
      },
      options2: {
        autoplay: false,
        centerMode: false,
        dots: false,
        navButtons: false,
        slidesToShow: 2,
        autoplaySpeed: 1000,
        speed: 100,
      },
      categoryList: [],
    };
  },
  mounted: function () {
    this.asNavFor1.push(this.$refs.thumbnails);
    this.asNavFor2.push(this.$refs.main);
  },
  async created() {
    this.categoryList = await this.$store.dispatch("getCategoryList");
  },
};
</script>
<style lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
#kv {
  .agile__list {
    @include mixin.liquidSize(margin-bottom, 0, 20);
  }
  .agile__dot {
    background: #b5dae7;
    @include mixin.liquidSize(width, 13, 13);
    @include mixin.liquidSize(height, 13, 13);
    border-radius: 50%;
    @include mixin.liquidSize(margin-left, 0, 4);
    @include mixin.liquidSize(margin-right, 0, 4);
  }
  .agile__dot--current {
    background: #1890a7;
  }
}
</style>
<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.main {
  width: 100%;
  @include mixin.liquidSize(height, 564, 438);
  @include mixin.liquidSize(margin-bottom, 24, 90);
  @include media.min-screen(media.$breakpoint-md) {
    height: calc(564 / 1320 * 100vw);
  }
  position: relative;
}
.slide--main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    min-width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include media.max-screen(media.$breakpoint-sm) {
      width: auto;
      max-width: none;
    }
  }
}
.thumbnails {
  //@include mixin.liquidSize(width, 900, 217);
  @include mixin.liquidSize(width, 600, 217);
  margin-left: auto;
  margin-right: auto;
  @include mixin.liquidSize(margin-bottom, 80, 0);
}

.slide--thumbnail {
  @include mixin.liquidSize(width, 300, 217);
  @include mixin.liquidSize(height, 119, 135);
  overflow: hidden;
  padding: 0 10px;
  box-sizing: border-box;
  position: relative;
  @include media.min-screen(media.$breakpoint-md) {
    max-width: 300px;
  }
  &:before {
    content: "";
    width: calc(100% - 20px);
    height: 100%;
    box-sizing: border-box;
    border: solid 2px #1890a7;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    transition: all 0.4s ease;
  }
  &.agile__slide--active {
    &:before {
      border: solid 4px #1890a7;
    }
  }
}

.bnrs {
  display: flex;
  @include mixin.liquidSize(width, 885, 704);
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}
.bnrs-item {
  display: block;
  position: relative;
  &.about {
    @include mixin.liquidSize(width, 273, 217);
    @include mixin.liquidSize(height, 127, 135);
  }
  &.flow {
    @include mixin.liquidSize(width, 273, 218);
    @include mixin.liquidSize(height, 157, 135);
    @include mixin.liquidSize(top, -15, 0);
  }
  &.faq {
    @include mixin.liquidSize(width, 276, 217);
    @include mixin.liquidSize(height, 137, 151);
    @include mixin.liquidSize(top, -10, -16);
  }
}

.head {
  @include mixin.liquidSize(margin-bottom, 30, 45);
}

//-カテゴリから探す
#category {
  @include mixin.liquidSize(padding-top, 120, 120);
}
.category-blk {
  position: relative;
  &:before {
    content: "";
    background: #f7f5f2;
    position: absolute;
    right: 0;
    top: 0;
    @include media.max-screen(media.$breakpoint-sm) {
      width: calc(50% + 263 / 750 * 100vw);
      height: calc(100% - 5 / 750 * 100vw);
      top: calc(32 / 750 * 100vw);
    }
    @include media.min-screen(media.$breakpoint-md) {
      width: calc(50% + 572px);
      height: calc(100% - 33px);
      top: 42px;
    }
  }
}
//-シーン別に探す
#scene {
  @include mixin.liquidSize(padding-top, 120, 120);
  @include mixin.liquidSize(padding-bottom, 60, 50);
}
//取引の流れ
#flow {
  @include mixin.liquidSize(padding-top, 60, 50);
}
</style>
