<!-- 日英 横並び -->
<template lang="pug">
  router-link.scene-list__item(:to="url")
    .blk
      .img
        img(:src="loadImg")
      .inner
        span.icon
          img(:src="loadIcon")
        span.ja(v-html="ja")
        span.en(v-html="en")
        span.arrow
          img(src="@/assets/icon-arrow-1.svg")
</template>

<script>
export default {
  name: "sceneItem",
  props: {
    icon: String,
    img: String,
    ja: String,
    en: String,
    url: String,
  },
  computed: {
    loadImg() {
      return require("@/assets/" + this.img);
    },
    loadIcon() {
      return require("@/assets/" + this.icon);
    },
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.scene-list__item {
  box-sizing: border-box;
  color: #000;
  position: relative;
  z-index: 2;
  @include mixin.liquidSize(padding-right, 40, 40);
  @include mixin.liquidSize(margin-bottom, 10, 30);
  .blk {
    @include mixin.liquidSize(min-width, 250, 280);
    @include mixin.liquidSize(max-width, 250, 280);
    @include mixin.liquidSize(height, 400, 430);
    background: rgba(255, 255, 255, 0.94);
    @include mixin.liquidSize(border-radius, 6, 6);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
    overflow: hidden;
  }
  @include media.min-screen(media.$breakpoint-md) {
    transition: all 0.4s ease-in-out 0.1s;
    &:hover {
      .img img {
        transform: scale(1.06);
      }
      .inner {
        transform: translate(-0%, 60%) scale(1.05);
        @include mixin.liquidSize(height, 203, 216);
      }
    }
  }
}

.img {
  width: 100%;
  @include mixin.liquidSize(height, 230, 246);
  overflow: hidden;
  position: relative;
  z-index: 1;
  img {
    transition: all 0.4s ease-in-out 0.1s;
    object-fit: contain;
  }
}

.inner {
  @include mixin.liquidSize(width, 210, 250);
  @include mixin.liquidSize(height, 183, 196);
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mixin.liquidSize(border-radius, 6, 6);
  position: absolute;
  z-index: 2;

  @include mixin.liquidSize(left, 20, 15);
  box-sizing: border-box;
  background: #fff;
  @include mixin.liquidSize(padding-top, 17, 18);
  transition: all 0.4s ease-in-out 0.1s;

  @include media.max-screen(media.$breakpoint-sm) {
    top: 0;
    transform: translate(0%, 100%);
  }
  @include media.min-screen(media.$breakpoint-md) {
    top: 0;
    transform: translate(-0%, 100%);
  }
}

.icon {
  @include mixin.liquidSize(width, 50, 50);
  @include mixin.liquidSize(height, 50, 50);
  @include mixin.liquidSize(margin-bottom, 10, 20);
}

.ja {
  font-weight: 700; /* bold */
  @include mixin.liquidSize(font-size, 17, 14);
  @include mixin.liquidSize(margin-bottom, 10, 20);
  text-align: center;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.en {
  font-weight: 500; /* medium */
  color: #848484;
  @include mixin.liquidSize(font-size, 12, 13);
  text-align: center;
}

.arrow {
  @include mixin.liquidSize(width, 17, 17);
  @include mixin.liquidSize(height, 17, 17);
  @include mixin.liquidSize(margin-top, 15, 10);
}
</style>
