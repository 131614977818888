<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "大阪でイベント備品のレンタルならカリトク",
    meta: [
      { name: "description", content: "カリトクはイベント運営のプロ集団「株式会社ノーレイジ」が運営する、イベント備品レンタルサービスです。大規模フェスやコンサート、大手企業の社内イベントや大規模会議など、これまで数々の催し事を運営してきた私たちだからこそできる、細かいアドバイスや提案が可能なカリトクは、従来の貸すだけのサービスではなく、お客様の催し事が成功することまで一緒にサポートさせていただくことで好評を得ております。" },
      { name: "keywords", content: "" },
    ],
  },
  async created() {
    if (sessionStorage.getItem("access") == null) {
      console.log("access");
      sessionStorage.setItem("access", "1");
      location.reload(true)
    } else {
      console.log("in session");
    }
  },
};
</script>
