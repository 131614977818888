<!-- 日英 横並び -->
<template lang="pug">
  router-link.category-list__item(:to="'category/' + url")
    span.icon
      img(:src="icon")
    .name
      span.ja {{ ja }}
      span.en {{ en }}
    span.arrow
      img(src="@/assets/icon-arrow-1.svg")
</template>

<script>
export default {
  name: "categoryItem",
  props: {
    icon: String,
    ja: String,
    en: String,
    url: String,
  },
  // computed: {
  //   loadImg() {
  //     return require("@/assets/" + this.icon);
  //   },
  // },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.category-list__item {
  @include mixin.liquidSize(width, 414, 336);
  @include mixin.liquidSize(height, 119, 110);
  background: rgba(255, 255, 255, 0.94);
  @include mixin.liquidSize(border-radius, 6, 6);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: #000;
  position: relative;
  z-index: 2;
  @include mixin.liquidSize(padding-left, 29, 23);
  @include mixin.liquidSize(padding-right, 24, 20);
  @include mixin.liquidSize(margin-bottom, 25, 25);
  &:not(:nth-of-type(3n)) {
    @include mixin.liquidSize(margin-right, 38, 0);
  }
  @include media.max-screen(media.$breakpoint-sm) {
    &:nth-of-type(odd) {
      margin-right: auto;
    }
  }
  @include media.min-screen(media.$breakpoint-md) {
    transition: all 0.4s ease-in-out 0.1s;
    &:hover {
      transform: scale(1.06);
      box-shadow: 9px 9px 10px rgba(0, 0, 0, 0.1);
    }
  }
}

.icon {
  @include mixin.liquidSize(width, 45, 36);
  @include mixin.liquidSize(height, 48, 39);
  @include mixin.liquidSize(margin-right, 25, 20);
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    object-fit: contain;
  }
}
.name {
}
.ja {
  font-weight: 700; /* bold */
  @include mixin.liquidSize(font-size, 18, 19);
  @include mixin.liquidSize(margin-right, 12, 17);
  display: block;
  @include mixin.liquidSize(margin-bottom, 3, 0);
}
.en {
  font-weight: 500; /* medium */
  color: #848484;
  @include mixin.liquidSize(font-size, 12, 13);
}
.arrow {
  @include mixin.liquidSize(width, 17, 14);
  @include mixin.liquidSize(height, 17, 14);
  margin-left: auto;
}
</style>
