<template lang="pug">
  section#contact
    .m-content.contact-inner
      p.contact-desc ご相談・お見積もり
        span.bold 無料
        |です。お気軽にお問い合わせ下さい。

      .list
        a.list-item(href="tel:06-6534-5963")
          img(src="@/assets/img-contact-1.jpg")
        a.list-item(href="mailto:info@kari-toku.jp")
          img(src="@/assets/img-contact-2.jpg")
</template>

<script>
import head1 from "@/components/common/head-1.vue";
export default {
  name: "contact",
  components: {
    head1,
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;

#contact {
  box-sizing: border-box;
  @include mixin.liquidSize(padding-bottom, 100, 0);
  @include media.max-screen(media.$breakpoint-sm) {
    background: #f9f9f9;
  }
}

.contact-inner {
  @include mixin.liquidSize(padding-top, 40, 50);
  @include mixin.liquidSize(padding-bottom, 40, 50);
  @include mixin.liquidSize(padding-left, 50, 70);
  @include mixin.liquidSize(padding-right, 50, 70);
  box-sizing: border-box;
  @include media.max-screen(media.$breakpoint-sm) {
    width: 100%;
  }
  @include media.min-screen(media.$breakpoint-md) {
    background: #f9f9f9;
  }
}

.contact-desc {
  @include mixin.liquidSize(font-size, 19, 21);
  letter-spacing: 0.015em;
  color: #1890a7;
  @include mixin.liquidSize(margin-bottom, 30, 25);
}

.list {
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
  }
}

.list-item {
  display: block;
  @include mixin.liquidSize(width, 604, 604);
  @include mixin.liquidSize(border-radius, 9, 9);
  overflow: hidden;
  box-sizing: border-box;
  border:solid 2px #fff;
  &:not(:last-of-type) {
    @include mixin.liquidSize(margin-bottom, 0, 28);
  }
  @include media.max-screen(media.$breakpoint-sm) {
    margin-left: auto;
    margin-right: auto;
  }
  @include media.min-screen(media.$breakpoint-md) {
    &:nth-of-type(odd) {
      margin-right: auto;
    }
    transition: all 0.4s ease-in-out;
    &:hover {
      border: solid 2px #1890a7;
    }
  }
}
</style>
