import Vue from "vue";
import Vuex from "vuex";
import VueCookies from "vue-cookies";
import ApiClient from "@/lib/http.js";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    categoryList: [],
    errors: [],
  },
  getters: {
    token(state) {
      if (!state.token) {
        let key = "__jwt_token_admin";
        if (location.pathname.indexOf("/admin") < 0) {
          key = "__jwt_token_user";
        }
        if (!VueCookies.get(key)) {
          return "";
        }
        state.token = VueCookies.get(key);
      }
      return state.token;
    },
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      let key = "__jwt_token_admin";
      if (location.pathname.indexOf("/admin") < 0) {
        key = "__jwt_token_user";
      }
      VueCookies.set(key, token);
    },
    setCategoryList(state, categoryList) {
      state.categoryList = categoryList;
    },
    setErrors(state, errors) {
      state.errors = errors;
    },
  },
  actions: {
    async getCategoryList(context) {
      if (context.state.categoryList.length == 0) {
        const api = new ApiClient();
        let data = await api.get("/category", null, null, false);
        context.commit("setCategoryList", data.list);
      }
      return context.state.categoryList;
    },
  },
  modules: {},
});
