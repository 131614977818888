<template lang="pug">
  .category-list.m-content
    categoryItem(
      v-for="row, key in categoryList" :key="key"
      :icon="row.icon"
      :ja="row.title"
      :en="row.sub_title"
      :url="row.id"
    )

</template>

<script>
import categoryItem from "@/components/user/home/categoryItem.vue";

export default {
  name: "category",
  components: {
    categoryItem,
  },
  props: {
    categoryList: [],
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.category-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  position: relative;
}
</style>
