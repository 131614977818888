<template lang="pug">
  div
    form(@submit.prevent="item_search")
      section.search.m-md
        input(type="search" placeholder="商品を検索する" @input="change_keyword")
        button(type="submit") 検索
    section.totop
      .m-content.totop-inner
        a.totop-link(href="#") ページの先頭へ
          span.icon
            img(src="@/assets/icon-totop.svg")
    footer#footer
      .footer-inner
        .footer-blk
          .info
            .info-logo
              img(src="@/assets/logo-vertical-1.svg")

            ul.info-data
              li.info-data__item 運営会社：株式会社NO LAZE（ノーレイジ）
              li.info-data__item 〒550-0012 大阪市西区立売堀1-9-11 KSビル602号
              li.info-data__item 06-6534-5963 （代表）

          nav#footer-nav
            router-link.nav__link(to="/about/")
              span.ja カリトクとは
              span.en About
            router-link.nav__link(to="/flow/")
              span.ja 取引の流れ
              span.en Flow
            router-link.nav__link(to="/delivery/")
              span.ja 配送・料金
              span.en Proces
            a.nav__link(href="#contact")
              span.ja お問い合わせ
              span.en Contact
            //-router-link.nav__link(to="/company/")
              span.ja 運営会社について
              span.en Company
        span.copyright Copyright &copy;2022 Karitoku. All rights reserved.
</template>

<script>
import head1 from "@/components/common/head-1.vue";
export default {
  name: "Footer",
  components: {
    head1,
  },
  data: function () {
    return {
      keyword: "",
    };
  },

  methods: {
    change_keyword(e) {
      this.keyword = e.target.value;
    },
    item_search() {
      this.$router.push("/search/" + this.keyword);
    },
  },
  watch: {
    $route() {
      location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;

.search {
  justify-content: center;
  align-items: center;
  background: {
    image: url(../../../assets/bg-search.jpg);
    size: cover;
    repeat: no-repeat;
  }
  @include mixin.liquidSize(height, 130, 0);
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
  }
  button {
    width: 70px;
    height: 35px;
    margin-left: 10px;
    background: #1890a7;
    color: #fff;
    font-size: 13px;
    transition: all 0.3s ease-in-out;
    border-radius: 16px;
    &:hover {
      background: #12869b;
      color: #fff;
    }
  }
}

input[type="search"] {
  box-sizing: border-box;
  border: solid 1px #a2a2a2;
  width: 465px;
  height: 35px;
  border-radius: 21px;
  padding: 0 25px;
  background: {
    color: #fff;
    image: url(../../../assets/icon-search-1.svg);
    repeat: no-repeat;
    position: top 10px right 10px;
    size: 15px auto;
  }
}

.totop {
  background: #1f1f1f;
  @include mixin.liquidSize(height, 44, 44);
  display: flex;
  align-items: center;
}

.totop-inner {
  display: flex;
  align-items: center;
}

.totop-link {
  color: #fff;
  display: block;
  @include mixin.liquidSize(font-size, 13, 20);
  @include media.max-screen(media.$breakpoint-sm) {
    margin-left: auto;
  }
  @include media.min-screen(media.$breakpoint-md) {
    margin: 0 44px 0 auto;
  }
  .icon {
    @include mixin.liquidSize(margin-left, 10, 10);
    img {
      @include mixin.liquidSize(width, 7, 10);
      @include mixin.liquidSize(height, 14, 20);
      position: relative;
      @include mixin.liquidSize(top, 3, 7);
    }
  }
}

#footer {
  background: #2a2a2a;
  box-sizing: border-box;
  @include mixin.liquidSize(padding-top, 30, 40);
  @include mixin.liquidSize(padding-bottom, 50, 50);
}

.footer-inner {
  @include media.min-screen(media.$breakpoint-md) {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (min-width: 768px) and (max-width: 1400px) {
    box-sizing: border-box;
    padding: 0 0 0 35px;
  }
}

.footer-blk {
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
  }
}

.info {
  box-sizing: border-box;
  @include mixin.liquidSize(padding-left, 0, 25);
  @include mixin.liquidSize(padding-right, 0, 25);
  @include mixin.liquidSize(margin-bottom, 0, 40);
}

.info-logo {
  @include mixin.liquidSize(width, 156, 260);
  @include mixin.liquidSize(height, 73, 121);
  @include mixin.liquidSize(margin-bottom, 34, 46);
}

.info-data__item {
  color: #fff;
  @include mixin.liquidSize(font-size, 13, 27);
  line-height: 1.75;
  font-weight: 300; /* light */
  letter-spacing: 0.09em;
}

#footer-nav {
  @include mixin.liquidSize(margin-bottom, 0, 90);
  @include media.min-screen(media.$breakpoint-md) {
    display: flex;
    margin-left: auto;
    margin-right: 44px;
  }
}

.nav__link {
  display: flex;
  @include mixin.liquidSize(padding-left, 0, 25);
  @include mixin.liquidSize(padding-right, 0, 25);
  box-sizing: border-box;
  @include mixin.liquidSize(height, auto, 65);
  @include media.max-screen(media.$breakpoint-sm) {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      border-bottom: solid 1px #707070;
    }
  }
  @include media.min-screen(media.$breakpoint-md) {
    flex-direction: column;
    margin-left: 80px;
  }
  @media screen and (min-width: 768px) and (max-width: 1400px) {
    margin-left: calc(80 / 1440 * 100vw);
    white-space: nowrap;
  }
  .ja {
    color: #fff;
    @include mixin.liquidSize(font-size, 13, 23);
    font-weight: 700; /* bold */
    line-height: 1;
    letter-spacing: 0.025em;
    @include mixin.liquidSize(margin-bottom, 3, 0);
    white-space: nowrap;
  }
  .en {
    color: rgba(255, 255, 255, 0.57);
    @include mixin.liquidSize(font-size, 11, 13);
    font-weight: 500; /* medium */
    @include mixin.liquidSize(margin-left, 0, 30);
    white-space: nowrap;
  }
}

.copyright {
  color: rgba(255, 255, 255, 0.61);
  letter-spacing: 0.07em;
  @include mixin.liquidSize(font-size, 12, 14);
  display: block;
  box-sizing: border-box;
  @include media.max-screen(media.$breakpoint-sm) {
    text-align: center;
  }
  @include media.min-screen(media.$breakpoint-md) {
    text-align: right;
    padding-right: 44px;
  }
}
</style>
