<template lang="pug">
  .scene-list
    .inner
      sceneItem(
        icon="icon-exhibition.png"
        img="img-exhibition.jpg"
        ja="展示会"
        en="Exhibition"
        url="/scene/exhibition/"
      )

      sceneItem(
        icon="icon-festival.png"
        img="img-festival.jpg"
        ja="フェスティバル・お祭り"
        en="Festival"
        url="/scene/festival/"
      )

      sceneItem(
        icon="icon-food.png"
        img="img-food.jpg"
        ja="フードイベント/<br>マルシェ/試食会"
        en="Food Event"
        url="/scene/food/"
      )

      sceneItem(
        icon="icon-freemarket.png"
        img="img-freemarket.jpg"
        ja="フリーマーケット"
        en="Free Market"
        url="/scene/freemarket/"
      )

      sceneItem(
        icon="icon-ceremony.png"
        img="img-ceremony.jpg"
        ja="式典/セレモニー"
        en="Ceremony"
        url="/scene/ceremony/"
      )

      sceneItem(
        icon="icon-presentation.png"
        img="img-presentation.jpg"
        ja="商品発表会"
        en="Presentation"
        url="/scene/presentation/"
      )

      sceneItem(
        icon="icon-conference.png"
        img="img-conference.jpg"
        ja="記者発表会"
        en="Conference"
        url="/scene/conference/"
      )

      sceneItem(
        icon="icon-briefing.png"
        img="img-briefing.jpg"
        ja="就職説明会/面接会場"
        en="Briefing"
        url="/scene/briefing/"
      )

      sceneItem(
        icon="icon-awards.png"
        img="img-awards.jpg"
        ja="入社式/表彰式"
        en="Ceremony"
        url="/scene/awards/"
      )

      sceneItem(
        icon="icon-funeral.png"
        img="img-funeral.jpg"
        ja="葬儀/地鎮祭"
        en="Funeral/Ground Breaking"
        url="/scene/funeral/"
      )

      sceneItem(
        icon="icon-sports.png"
        img="img-sports.jpg"
        ja="運動会"
        en="Sports Festival"
        url="/scene/sports/"
      )













</template>

<script>
import sceneItem from "@/components/user/common/sceneItem.vue";

export default {
  name: "scene",
  components: {
    sceneItem,
  },
};
</script>

<style lang="scss" scoped>
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.scene-list {
  position: relative;
  z-index: 1;
  position: relative;
  overflow: scroll;
  box-sizing: border-box;
  @include mixin.liquidSize(padding-bottom, 6, 6);
  @include media.max-screen(media.$breakpoint-sm) {
    padding-left: calc(25 / 750 * 100vw);
  }
  @include media.min-screen(media.$breakpoint-md) {
    padding-left: calc(50% - 660px);
  }
  @media screen and (min-width: 769px) and (max-width: 1400px) {
    padding-left: 4.5vw;
  }
}
.inner {
  display: flex;
  flex-wrap: nowrap;
}
</style>
