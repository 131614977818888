<template lang="pug">
  .bnrs
    router-link.bnrs-item.about(to="/about/" :class="{seeing: seeing == 'about'}")
      img.m-sm(src="@/assets/about-sm.svg")
      img.m-md(src="@/assets/about-md.svg")
    router-link.bnrs-item.flow(to="/flow/" :class="{seeing: seeing == 'flow'}")
      img.m-sm(src="@/assets/flow-sm.svg")
      img.m-md(src="@/assets/flow-md.svg")
    router-link.bnrs-item.faq(to="/delivery/" :class="{seeing: seeing == 'faq'}")
      img.m-sm(src="@/assets/faq-sm.svg")
      img.m-md(src="@/assets/faq-md.svg")
</template>

<script>
export default {
  name: "bnrs",
  props: {
    icon: String,
    img: String,
    ja: String,
    en: String,
    url: String,
    seeing: String,
  },
};
</script>

<style scoped lang="scss">
@use "@/css/utility/_colors" as colors;
@use "@/css/utility/_mixin" as mixin;
@use "@/css/utility/_media" as media;
@use "@/css/utility/_font" as font;
@use "@/css/utility/_variables" as variables;
.bnrs {
  display: flex;
  @include mixin.liquidSize(width, 885, 704);
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
}
.bnrs-item {
  display: block;
  position: relative;
  img {
    position: relative;
    z-index: 2;
  }
  &:before,
  &:after {
    content: "";
    @include mixin.liquidSize(border-radius, 9, 15);
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    @include mixin.liquidSize(width, 273, 218);
    @include mixin.liquidSize(height, 127, 136);
    transition: all 0.3s ease-in-out;
  }
  &:before {
    border: solid 1px rgba(19, 155, 181, 0.85);
  }
  &:after {
    border: solid 2px rgba(19, 155, 181, 0.85);
    opacity: 0;
    transition: all 0.3s ease-in-out;
  }
  &.seeing {
    &:after {
      opacity: 1;
    }
  }
  @include media.min-screen(media.$breakpoint-md) {
    &:hover {
      &:after {
        opacity: 1;
      }
    }
  }
  &.about {
    @include mixin.liquidSize(width, 273, 217);
    @include mixin.liquidSize(height, 127, 135);
  }
  &.flow {
    @include mixin.liquidSize(width, 273, 218);
    @include mixin.liquidSize(height, 157, 135);
    @include mixin.liquidSize(top, -15, 0);
    &:before,
    &:after {
      @include mixin.liquidSize(top, 15, 0);
    }
  }
  &.faq {
    @include mixin.liquidSize(width, 276, 217);
    @include mixin.liquidSize(height, 137, 151);
    @include mixin.liquidSize(top, -10, -16);
    &:before,
    &:after {
      @include mixin.liquidSize(top, 10, 16);
    }
  }
}
</style>
